<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 73%;display: flex;align-items: center;">
          <el-input
            class="hunt"
            v-model="name"
            placeholder="请输入会员姓名"
            size="large"
            @input="orangutan"
			clearable 
          />
          <el-input
            class="hunt"
            v-model="topic"
            placeholder="请输入会员编码"
            size="large"
            @input="orangutan"
			clearable 
          />
          <!-- 时间选择 -->
          <el-date-picker
            class="hunt"
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            size="large"
            @change="immediUpdate"
          />
          <!-- 会员组织名称搜索 -->
            <el-select
              class="hunt"
              v-model="ability"
              placeholder="请选择组织名称"
              size="large"
              @click="multiple"
              clearable
              @change="numerical"
			  @focus="closeIt" 
			  ref="selectIt"
            />
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <!-- <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div> -->
      </div>
      <div class="revocation">
        <div class="statistics">
          统计：{{altogether}}条数据
        </div>
        <div class="quota">
          <el-upload
              v-model:file-list="fileList"
              class="upload-demo"
              action
              :auto-upload="true" 
              :show-file-list="false"
              name="excelFile"
              accept=".xlsx,.xls"
              limit='20'
              :on-error="error"
              :http-request="uploadHttpRequest"
              ref='pleased'
              :disabled='jinyong'
            >
              <el-button type="primary" :loading="acting" :disabled='jinyong'>批量回收</el-button>
            </el-upload>
          <el-button type="warning" size="default" @click="download" :loading='whirl'>下载回收模板</el-button>
          <el-button type="danger" size="default" @click="goumai" :loading='whirl'>回收失败日志</el-button>
        </div>
      </div>
      <!-- <div class="summation">
        <div class="hint">统计 : {{altogether}}篇</div>
        <div class="hint">提示 : 动态只有发布人所管理工会会员可见，其他不可见。</div>
      </div> -->
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :empty-text="texts"
        :header-cell-style="{ background: '#F5F7FA' }"
      >
       <!-- 会员姓名 -->
        <el-table-column
          label="会员姓名"
          prop="name"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 会员编码  -->
        <el-table-column
          label="会员编码 "
          prop="username"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属工会 -->
        <el-table-column
          label="所属工会"
          prop="unionName"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属部门 -->
        <!-- <el-table-column
          label="所属部门"
          prop="parentUnionName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column> -->
        <!-- 回收时间 -->
        <el-table-column
          label="回收时间"
          prop="createTime"
          align="center"
          :formatter="reach"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 积分值 -->
        <el-table-column
          label="积分值"
          prop="integralValue"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 操作人  -->
        <el-table-column
          label="操作人"
          prop="adminName"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          label="备注 "
          prop="reason"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" width="265">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.username)"
              class="bulur"
              ><el-icon class="cancel"> <Search /> </el-icon>积分明细</el-button
            >
            <el-button size="default" @click.stop="expurgate(scope.row.username)" class="red"
              ><el-icon class="cancel"> <Search /> </el-icon>提货券明细</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[6, 12, 18, 24]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
  </div>
</template>
<script>
// import { useRouter  } from "vue-router";
import { ref,onMounted,onDeactivated } from 'vue';
import { HSintegral,Zzzlist,HSdownload,HSinformation,ZClist} from '../../utils/api'
import { Search } from "@element-plus/icons-vue";
import qs from 'qs'
import { useRouter  } from "vue-router";
import moments from 'moment'
import { ElMessage } from "element-plus";
export default {
  name:"JFrecall",
  setup() {
    // 搜索加载
    const formative=ref(false)
    // 批量收回按钮禁用/加载
    const acting=ref(false)
    const jinyong=ref(false)
    // 下载模板
    const download=()=>{
      HSdownload().then((res)=>{
        console.log(res)
        window.open(res.data.data.withdrawExcelExampleUrl)
      })
    }





    // 回收失败日志跳转
    const goumai=()=>{
      router.push('/JFfail')
    }
	
	let selectIt = ref()
	// 下拉隐藏
	let closeIt = () => {
		selectIt.value.blur();
	}





     // 导入
    // const error=(e)=>{
    //   console.log(e)
    // }
    const pleased=ref()
    const uploadHttpRequest = (data)=>{
      jinyong.value=true
      acting.value=true
      const file = data.file;
      const formData = new FormData()
      formData.append('file', file)
      let datas={
        excelFile:formData.get('file')
      }
      HSintegral(qs.parse(datas)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          list()
          ElMessage({
              message: "回收成功",
              type: "success",
              center: true,
            });
            pleased.value.clearFiles()
            jinyong.value=false
            acting.value=false
        }else if(res.data.code==-1){
          list()
          ElMessage({
            message: '回收失败',
            type: "error",
            center: true,
          });
          acting.value=false
          jinyong.value=false
        }else if(res.data.code!==0||res.data.code!==-1){
          list()
          ElMessage({
            message: res.data.msg,
            type: "error",
            center: true,
          });
          acting.value=false
          jinyong.value=false
        }
      })
    }









    // 回收时间
     let reach=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format("YYYY-MM-DD HH:mm")
      }
    }
    // 时间清空监听
    let a =ref('')
    let b =ref('')
    let c=ref('')
    let out=ref('')
    const immediUpdate=(e)=>{
      if(e!==null){
        a.value=new Date(e[0]).getTime()/1000
        b.value=new Date(e[1]).getTime()/1000
        c.value=moments(b.value*1000).format().substring(0,10)
        out.value= new Date( c.value+' '+ '23:59:59').getTime()/1000
      }else if(e==null){
        a.value=''
        b.value=''
        c.value=''
        out.value=''
      }
      console.log(e)
    }
    // 时间选择
    let value1=ref('')
    // 现金券明细
    // const expurgate=(res)=>{
    //   sessionStorage.setItem('YEticket', res)
    //   router.push('/YEticket')
    //   console.log(res)
    // }




    // 路由跳转
    const router = useRouter()
    // 积分明细
    // const copyreader=(res)=>{
    //   sessionStorage.setItem('YEintegral', res)
    //   router.push('/YEintegral')
    //   console.log(res)
    // }
    // 会员编码
    const topic=ref('')
    // 会员姓名
    const name = ref('')
    const zong =ref(null)
    // 工会清空
    const numerical=(e)=>{
      if(e==''){
        Hcode.value=''
      }
    }

    
        // 获取code 
    let Hcode=ref('')
    // 搜索组织选择值
    let ability=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    const lang=()=>{
      ZClist().then((res)=>{
        // console.log(res)
        texture.value = res.data.data.manageUnionList
      })
    }
    // 弹窗组织列表树形
    let texture =ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
     // 选择组织按钮
    let multiple =()=>{
      visible.value=true
    }
    // 搜索组织弹窗
    let visible=ref(false)










    // 列表内容显示
    let texts= ref('加载中')
    let search1=()=>{
      formative.value=true
      search()
    }
    // 搜索
    let search=()=>{
      console.log('开始时间',a.value)
      
      console.log('jieshu',out.value)
      console.log(Hcode.value)
      let data ={
        limit:branches.value,
        page:currentPage.value,
        name:name.value,
        username:topic.value,
        unionCode:Hcode.value,
        startTime:a.value,
        endTime:out.value
      }
      HSinformation(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          formative.value=false
          altogether.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(ability.value!==''||name.value!==''||topic.value!==''||a.value!==''||b.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(ability.value!==''||name.value!==''||topic.value!==''||a.value!==''||b.value!==''){
        search()
      }else{
        list()
      }
    }
    // 总条数
    let altogether=ref(0)
    // 工会动态列表
    let branches=ref(6)
    const list =()=>{
      let data ={
        limit:branches.value,
        page:currentPage.value,
        startTime:'',
        endTime:'',
        unionCode:'',
        name:'',
        username:''
      }
      HSinformation(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          altogether.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    onDeactivated(()=>{
      // list()
    })
    onMounted(()=>{
      list()
      lang()
    })
    // 头部搜索
    let values=ref('')
    // 路由跳转
    // const router = useRouter()
    // 列表数据循环
    const filterTableData = ref([])
    return {
      search1,
      formative,
      // 批量收回按钮是否禁用/加载
      acting,
      jinyong,
      pleased,
      // 下载模板
      download,
      // 回收失败日志跳转
      goumai,
      // 导入
      uploadHttpRequest,
      // 回收时间
      reach,
      // 时间选择
      immediUpdate,
      value1,
      // 现金券明细
      // expurgate,
      // 积分明细
      // copyreader,
      topic,
      name,
      zong,
      numerical,
      ability,
      texture,
      defaultProps,
      dendrogram,
      visible,
      multiple,
      texts,
      // 搜索
      search,
      // 分页
      handleSizeChange,
      handleCurrentChange,
      altogether,
      pagesize,
      currentPage,
      values,
      // 循环列表数据
      filterTableData,
	  closeIt,
	  selectIt,
    };
  },
  components: {
    Search
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-range-editor--large.el-input__wrapper{
  margin-right: 10px;
  margin-left: 10px;
}
.upload-demo{
  margin-right: 10px;
}
.quota{
  display: flex;
  align-items: center;
}
.revocation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.statistics{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #909399;
  // margin-bottom: 15px;
  margin-left: 10px;
}
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 165px);
  overflow: auto;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
.el-select--large{
  width: 20%;
  // margin-left: 10px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
  margin-left: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
/* ::-webkit-scrollbar{display:none} */
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}

.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
